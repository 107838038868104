import { Fab, Grid, makeStyles } from '@material-ui/core';
import { ArrowUpward } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { use100vh } from 'react-div-100vh';
import { animateScroll, Events } from 'react-scroll';
import { About, PoliticalCareer, SocialIntervention, Headers, EarlyLife, Menu, Awards } from '../components/index';
import { colors } from '../utils/Css';

const Details = () => {
  const classes = useStyle();
  const height = use100vh();
  const [active, setActive] = useState(false);

  useEffect(() => {
    Events.scrollEvent.register('begin');
    Events.scrollEvent.register('end');
    return () => {
      Events.scrollEvent.remove('begin');
      Events.scrollEvent.remove('end');
    };
  }, []);

  useEffect(() => {
    const onScroll = (e) => {
      setActive(e.target.documentElement.scrollTop > 40);
    };
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  const handleScrollToTop = () => {
    animateScroll.scrollToTop();
  };

  return (
    <div style={{ minHeight: height }}>
      <Headers isActive={active} />
      <Grid container className={classes.container}>
        <Grid item sm={3} classes={{ root: classes.menu }}>
          <Menu />
        </Grid>
        <Grid item sm={8} lg={6} classes={{ root: classes.content }}>
          <About />
          <EarlyLife />
          <PoliticalCareer />
          <SocialIntervention />
          <Awards />
        </Grid>
      </Grid>
      <Fab size="medium" aria-label="add" className={classes.fab} onClick={handleScrollToTop}>
        <ArrowUpward />
      </Fab>
    </div>
  );
};

export default Details;

const useStyle = makeStyles({
  container: {
    marginTop: '190px',
    paddingLeft: 10,

    '@media screen and (min-width: 960px)': {
      paddingLeft: 120
    }
  },
  menu: {
    paddingTop: '18px',

    '@media screen and (max-width: 960px)': {
      display: 'none'
    }
  },
  content: {
    top: '100px',

    '@media screen and (max-width: 960px)': {
      maxWidth: '100%',
      paddingRight: '15px',
      flexBasis: '100%',
    }
  },
  fab: {
    margin: 0,
    top: 'auto',
    right: 20,
    bottom: 20,
    left: 'auto',
    position: 'fixed',
    backgroundColor: colors.primary,
    '& .MuiFab-root:hover': {
      backgroundColor: '#fff',
      color: colors.primary
    }
  }
});
