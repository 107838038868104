import React, { useState } from 'react';
import { Container, Hidden, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { NavLink } from 'react-router-dom';
import Tweenful from 'react-tweenful';
import { use100vh } from 'react-div-100vh';
import logo from '../assests/logo.svg';
import image1 from '../assests/image1.png';
import { ReactComponent as Arrow } from '../assests/arrow.svg';
import { fontFamily } from '../utils/Css';
import useInterval from '../hooks/useInterval';

const contents = [
  {
    title: 'A Nigerian politician and youth leader',
    text: 'Ismaeel Buba Ahmed was appointed to the role of Senior Special Assistant on National Social Investments Programme by President Muhammadu Buhari on 12 June 2017.',
    image: image1
  }
];

const Home = () => {
  const [page, setPage] = useState(0);
  const height = use100vh();

  const classes = useStyle({ page, height });

  useInterval(() => {
    setPage((currentPage) => {
      return currentPage + 1 > contents.length - 1 ? 0 : currentPage + 1;
    });
  }, 8000);

  const handleChangePage = (direction) => {
    setPage((currentPage) => {
      let nextPage;
      if (direction === 'next') nextPage = currentPage + 1 > contents.length - 1 ? 0 : currentPage + 1;
      if (direction === 'prev') nextPage = currentPage - 1 < 0 ? contents.length - 1 : currentPage - 1;

      return nextPage;
    });
  };

  return (
    <Container disableGutters maxWidth={false} className={classes.homeContainer}>
      <div className={classes.imageShow} />
      <header className={classes.header}>
        <img src={logo} alt="logo" />
      </header>
      <div className={classes.homeContent}>
        <div className={classes.textBox}>
          {/* eslint-disable-next-line react/jsx-pascal-case */}
          <Tweenful.div
            duration={2500}
            easing="linear"
            animate={{ opacity: ['0', '1'] }}
          >
            <h3 className="pageNumber">{page + 1}/{contents.length}</h3>
            <h1 className="title">{contents[page].title}</h1>
            <Hidden smDown>
              <p className="text">{contents[page].text}</p>
            </Hidden>
          </Tweenful.div>
          <NavLink to="/more" className="btnMore">VIEW MORE <Arrow /></NavLink>
        </div>
        <div className={classes.btnGroup}>
          <Button endIcon={<Arrow />} className="btn btnLeft" onClick={() => handleChangePage('prev')} />
          <Button endIcon={<Arrow />} className="btn btnRight" onClick={() => handleChangePage('next')} />
        </div>
      </div>
      <Hidden smDown>
        <footer className={classes.homeFooter}>
          <p>Ismaeel Buba Ahmed</p>
        </footer>
      </Hidden>
    </Container>
  );
};

const useStyle = makeStyles({
  homeContainer: {
    height: ({ height }) => height,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },

  imageShow: {
    height: '100%',
    width: '100%',
    position: 'fixed',
    backgroundImage: ({ page }) => `url(${contents[page].image})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center right 18%',
    zIndex: '-1000'
  },

  header: {
    marginTop: 24,
    marginLeft: 24
  },

  homeContent: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },

  textBox: {
    backgroundColor: 'rgba(0, 0, 0, 0.32)',
    padding: '24px 24px 40px',
    border: '1px solid #FFFFFF',

    '& .pageNumber': {
      fontFamily: fontFamily.primary,
      color: '#EEEEEE',
      fontSize: 24
    },

    '& .title': {
      fontFamily: fontFamily.primary,
      color: '#EEEEEE',
      fontSize: 24,
      textTransform: 'uppercase',
      fontWeight: 700,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      maxWidth: '335px'
    },

    '& .text': {
      fontFamily: fontFamily.secondary,
      fontSize: 18,
      color: '#EEEEEE',
      marginBottom: 24
    },

    '& .btnMore': {
      fontFamily: fontFamily.secondary,
      backgroundColor: '#F9B800',
      color: '#000',
      fontSize: 18,
      padding: '14px 24px',
      textDecoration: 'none',
      display: 'flex',
      alignItems: 'center',
      maxWidth: 195,

      '& svg': {
        marginLeft: 10
      }
    }
  },

  btnGroup: {
    order: '-1',

    '& .btn': {
      padding: '24px 16px',
      backgroundColor: '#000',
    },

    '& .MuiButton-endIcon': {
      margin: 0
    },

    '& .btn svg path': {
      fill: '#E9A41B'
    },

    '& .btnLeft': {
      transform: 'matrix(-1, 0, 0, 1, 0, 0)'
    },

    '& .btnRight': {
      marginLeft: 2
    }
  },

  homeFooter: {
    fontFamily: fontFamily.primary,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    padding: '25px 110px',
    width: '100%',
    borderTop: '1px solid #FFFFFF',

    '& p': {
      fontWeight: 700,
      fontSize: 24,
      color: '#EEEEEE',
      margin: 0
    }
  },

  '@media only screen and (min-width: 960px)': {
    header: {
      marginLeft: 110
    },

    homeContent: {
      maxWidth: 580,
      marginLeft: 110
    },

    textBox: {
      padding: '24px 40px 40px',
    },

    btnGroup: {
      order: 1,
      alignSelf: 'flex-end',
      marginRight: 5
    }
  }
});

export default Home;
