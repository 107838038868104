/* eslint-disable react/no-unescaped-entities */
import { Divider, List, ListItem, ListItemText } from '@material-ui/core';
import React from 'react';
import { Element } from 'react-scroll';
import { makeStyles } from '@material-ui/core/styles';
import HeadingText from './HeadingText';
import { fontFamily } from '../utils/Css';

const Awards = () => {
  const classes = useStyles();

  return (
    <Element name="awards" className={classes.wrapper}>
      <HeadingText text="Awards" />
      <List style={{ paddingTop: 0, zIndex: -200 }}>
        <ListItem>
          <ListItemText primary="Most Influential People of African Descent (MIPAD), global 100 under 40 in 2019" className={classes.text} />
        </ListItem>
        <Divider variant="middle" component="li" />
        <ListItem>
          <ListItemText primary="Social Protection Advocate Award (South Africa)" className={classes.text} />
        </ListItem>
        <Divider variant="middle" component="li" />
        <ListItem>
          <ListItemText primary="Anti-Drug Abuse Ambassador Award" className={classes.text} />
        </ListItem>
        <Divider variant="middle" component="li" />
        <ListItem>
          <ListItemText primary="Nigerian Students Awards" className={classes.text} />
        </ListItem>
        <Divider variant="middle" component="li" />
        <ListItem>
          <ListItemText primary="Heroes of Democracy Awards" className={classes.text} />
        </ListItem>
        <Divider variant="middle" component="li" />
      </List>
    </Element>
  );
};

const useStyles = makeStyles({
  wrapper: {
    marginBottom: 220,

    '@media only screen and (min-width: 768px)': {
      marginBottom: 440,
    }

  },
  text: {
    '& .MuiTypography-body1': {
      fontSize: 15,
      fontFamily: fontFamily.secondary,
      color: '#413E3B',

      '@media only screen and (min-width: 960px)': {
        fontSize: 18
      }
    }
  }
});

export default Awards;
