/* eslint-disable react/no-unescaped-entities */
import { Divider, List, ListItem, ListItemText } from '@material-ui/core';
import React from 'react';
import { Element } from 'react-scroll';
import { makeStyles } from '@material-ui/core/styles';
import { fontFamily } from '../utils/Css';
import HeadingText from './HeadingText';

const SocialIntervention = () => {
  const classes = useStyles();

  return (
    <Element name="social-intervention">
      <HeadingText text="Social Intervention" />
      <List style={{ paddingTop: 0, zIndex: -200 }}>
        <ListItem>
          <ListItemText primary="Supported the President on Implementing the most ambitious and biggest Social Intervention Programme in Nigeria" className={classes.text} />
        </ListItem>
        <Divider variant="middle" component="li" />
        <ListItem>
          <ListItemText primary="Carefully planned the Communications and Monitoring & Evaluation Departments of the National Social Investment Programme" className={classes.text} />
        </ListItem>
        <Divider variant="middle" component="li" />
        <ListItem>
          <ListItemText primary="Championed the successful implementation of the SIPs to gain political mileage and effectively managed political stakeholders" className={classes.text} />
        </ListItem>
        <Divider variant="middle" component="li" />
        <ListItem>
          <ListItemText primary="Developed an effective communication strategy to amplify the achievements of the SIPs" className={classes.text} />
        </ListItem>
        <Divider variant="middle" component="li" />
        <ListItem>
          <ListItemText primary="Ensured the even spread of beneficiaries across all programmes within the country." className={classes.text} />
        </ListItem>
        <Divider variant="middle" component="li" />
      </List>
    </Element>
  );
};

const useStyles = makeStyles({
  text: {
    '& .MuiTypography-body1': {
      fontSize: 15,
      fontFamily: fontFamily.secondary,
      color: '#413E3B',

      '@media only screen and (min-width: 960px)': {
        fontSize: 18
      }
    }
  }
});

export default SocialIntervention;
