import React from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createMuiTheme, ThemeProvider, jssPreset, StylesProvider } from '@material-ui/core/styles';
import jssPluginSyntaxExtend from 'jss-plugin-extend';
import { create } from 'jss';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const jss = create({
  plugins: [jssPluginSyntaxExtend(), ...jssPreset().plugins],
});

const theme = createMuiTheme({
  typography: {
    htmlFontSize: 10,
    fontSize: 10
  },
  shape: {
    borderRadius: 0
  },
  MuiCssBaseline: {
    '@global': {
      html: {
        fontSize: '62.5%'
      }
    }
  },
});

ReactDOM.render(
  <StylesProvider jss={jss}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  </StylesProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
