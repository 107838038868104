import { makeStyles } from '@material-ui/core';
import React from 'react';
import { NavLink } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import HeaderPic from '../assests/headerImage.png';
import HeaderPicMobile from '../assests/headerImage-mobile.png';
import Logo from '../assests/logo.svg';
import { colors, fontFamily } from '../utils/Css';

const Headers = ({ isActive }) => {
  const classes = useStyles({ isActive });
  return (
    <>
      <div className={classes.container}>
        <div className={classes.header}>
          <NavLink to="/" style={{ }}><img src={Logo} alt="Ismaeel" /></NavLink>
          <Typography className={classes.title} style={{ display: isActive && 'none' }}>
            Ismaeel Buba Ahmed
          </Typography>
        </div>
      </div>
    </>
  );
};

export default Headers;

const useStyles = makeStyles({
  container: {
    display: 'flex',
    width: '100%',
    position: 'fixed',
    backgroundImage: `url(${HeaderPicMobile})`,
    backgroundRepeat: 'no-repeat',
    height: ({ isActive }) => (isActive ? '95px' : '171px'),
    maxHeight: 171,
    transition: 'height 0.2s linear',
    top: 0,
    paddingBottom: 10,
    backgroundSize: 'contain',
    backgroundColor: '#000',
    backgroundPosition: 'center right 1%',

    '@media only screen and (min-width: 960px)': {
      backgroundImage: `url(${HeaderPic})`
    }
  },
  header: {
    height: ({ isActive }) => (isActive ? '30%' : '80%'),
    paddingLeft: 130,
    marginTop: 24,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',

    '@media screen and (max-width: 960px)': {
      paddingLeft: 24
    }
  },
  title: {
    color: colors.primary,
    fontSize: 24,
    fontWeight: 700,
    marginTop: '10%',
    textTransform: 'uppercase',
    fontFamily: fontFamily.primary,
    // display: ({ isActive }) => (isActive ? 'none' : 'block'),
    // opacity: ({ isActive }) => (isActive ? 0 : 1),

    '@media screen and (min-width: 960px)': {
      marginTop: 50,
    }
  },
});

Headers.propTypes = {
  isActive: PropTypes.bool.isRequired
};
