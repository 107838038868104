import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { fontFamily } from '../utils/Css';

export default function SubText({ text }) {
  const classes = useStyles();

  return (
    <p className={classes.text} dangerouslySetInnerHTML={{ __html: text }} />
  );
}

SubText.propTypes = {
  text: PropTypes.string.isRequired,
};

const useStyles = makeStyles({
  text: {
    fontSize: 15,
    paddingLeft: 14,
    fontFamily: fontFamily.secondary,
    color: '#413E3B',
    marginTop: 10,

    '@media only screen and (min-width: 960px)': {
      fontSize: 18
    }
  }
});
