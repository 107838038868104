import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { colors, fontFamily } from '../utils/Css';

export default function HeadingText({ text }) {
  const classes = useStyle();

  return (
    <h1 className={classes.text}>
      <FiberManualRecordIcon className={classes.icon} />
      <span>{text}</span>
    </h1>
  );
}

HeadingText.propTypes = {
  text: PropTypes.string.isRequired,
};

const useStyle = makeStyles({
  text: {
    fontFamily: fontFamily.tertiary,
    fontSize: 24,
    paddingLeft: 10,
    color: '#361D00',
    marginBottom: 0,
    display: 'flex',
    alignItems: 'center',

    '@media only screen and (min-width: 960px)': {
      fontSize: 36
    }
  },
  icon: { color: colors.primary, width: 20, marginRight: 8 }
});
