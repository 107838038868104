import React from 'react';
import { Element } from 'react-scroll';
import HeadingText from './HeadingText';
import SubText from './SubText';

const EarlyLife = () => {
  return (
    <Element name="early-life">
      <HeadingText text="Early Life and Education" />
      <SubText
        text={`Ismaeel Ahmed was born and raised in the ancient commercial city of Kano.
        He attended Government Secondary School, Bwari, Abuja in 1998.
        He then proceed to the University of Abuja where he graduated with a
        Bachelor of Laws degree in 2005.<br /><br />
        Ismaeel finished from the Nigerian Law School,
        Abuja in 2006 before proceeding to Webster University in St Louis, Missouri, United States,
        where he bagged a Masters Degree in International Relations,
        Communications and Diplomacy in 2008.`}
      />
    </Element>
  );
};

export default EarlyLife;
