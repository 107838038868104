/* eslint-disable react/prop-types */
import { List, ListItem, ListItemText, makeStyles } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-scroll';
import { colors, fontFamily } from '../utils/Css';

const Menu = () => {
  const classes = useStyles();
  return (
    <div className={classes.menuWrapper}>
      <List component="nav">
        <ListItem>
          <ListItemText>
            <Link
              activeClass={classes.active}
              className={classes.navText}
              to="about"
              spy
              smooth
              duration={500}
              offset={-350}
            >About
            </Link>
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            <Link
              activeClass={classes.active}
              className={classes.navText}
              to="early-life"
              spy
              smooth
              duration={500}
              offset={-230}
            >Early Life &  Education
            </Link>
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            <Link
              activeClass={classes.active}
              className={classes.navText}
              to="political-career"
              spy
              smooth
              duration={500}
              offset={-230}
            >Political Career
            </Link>
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            <Link
              activeClass={classes.active}
              className={classes.navText}
              to="social-intervention"
              spy
              smooth
              duration={500}
              offset={-230}
            >Social Intervention
            </Link>
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            <Link
              activeClass={classes.active}
              className={classes.navText}
              to="awards"
              spy
              smooth
              duration={500}
              offset={-230}
            >Awards
            </Link>
          </ListItemText>
        </ListItem>
      </List>
    </div>
  );
};

export default Menu;

const useStyles = makeStyles({
  navText: {
    fontWeight: 700,
    fontSize: 16,
    color: colors.text,
    padding: '2px 8px',
    fontFamily: fontFamily.tertiary,
    '&:hover': {
      color: colors.primary,
      cursor: 'pointer',
    }
  },
  active: {
    fontWeight: 700,
    fontSize: 16,
    color: colors.text,
    boxShadow: `-2px 0px 0px ${colors.primary}`,
    padding: '2px 8px'
  },
  menuWrapper: {
    position: 'sticky',
    top: '150px',
    zIndex: -200
  }
});
