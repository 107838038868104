export const fontFamily = {
  primary: '"Montserrat", "Roboto", sans-serif',
  secondary: '"Open Sans", "Roboto", sans-serif',
  tertiary: '"Noto Serif", serif',
};

export const colors = {
  primary: '#F9B800',
  text: '#361D00',
};
