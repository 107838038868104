import React from 'react';
import { Element } from 'react-scroll';
import SubText from './SubText';
import HeadingText from './HeadingText';

const About = () => {
  return (
    <Element name="about">
      <HeadingText text="About" />
      <SubText
        text={`Ismaeel Buba Ahmed, is a Nigerian politician and youth leader.
        He was appointed the role of Senior Special Assistant on National Social
        Investments Programme by President Muhammadu Buhari on 12 June 2017.
        He is a Lawyer and an advocate of personnel advancement,
        social development and good governance. <br /><br />
        
        With over 10 years of political administration and
        policy implementation at the federal level,
        he founded the youth wing of the All Progressive Congress (APC) ,
        called APC Youth Forum Where he was chairman of the APC Youth Forum
        from 2013 until March 2018. He also played a major role alongside the President of the
        Federal Republic of Nigeria in implementing the biggest
        social intervention programme in Nigeria with over 12 million beneficiaries.<br /><br />
        
        Ismaeel B. Ahmed is a thought leader on the transformative role social intervention
        plays in a developing country.
        He was awarded Most Influential People of African Descent (MIPAD),
        global 100 under 40 in 2019, Social Protection Advocate Award (South Africa),
        Anti-Drug Abuse Ambassador Award, Nigerian Students Awards and Heroes of Democracy Awards.
        He is a member of the Board of Trustees of Nigeria’s ruling Party APC.`}
      />
    </Element>
  );
};

export default About;
