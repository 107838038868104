/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Element } from 'react-scroll';
import HeadingText from './HeadingText';
import SubText from './SubText';

const PoliticalCareer = () => {
  return (
    <Element name="political-career">
      <HeadingText text="Political Career" />
      <SubText
        text={`In 2011, Ismaeel Ahmed contested and won the
        primary election to represent Nassarawa Federal Constituency,
        Kano State, at the House of Representatives under the
        Congress for Progressive Change (CPC).<br /><br />
        The following year he was selected as one of the members of the CPC Renewal Committee.
        In 2013, major opposition parties in Nigeria, including the CPC,
        formed a merger ahead of the 2015 general elections.<br /><br />
        Ismaeel Ahmed was a member of the manifesto sub-committee of that merger
        that eventually produced the party in power All Progressives Congress.
        Soon after the merger, Ismaeel founded the Youth Wing of the new party,
        called APC Youth Forum.<br /><br />
        Ismaeel Ahmed was the Chairman of the APC Youth Forum from 2013
        until March 2018 when he handed over to Ife Adebayo.
        Ismaeel is a member of the Board of Trustees of Nigeria's
        ruling party All Progressives Congress.`}
      />
    </Element>
  );
};

export default PoliticalCareer;
